.header {
  height: 80px;
  position: relative;
  margin: 0 auto;
  z-index: 1;
  background: #000;
}

.logoHeader {
  transform: translateY(-10px);
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}

.headerMenu {
  display: flex;
  width: 100%;
  height: 80px;
  align-items: center;
}

.headerMenu li a {
  display: flex;
  padding: 10px 15px;
  color: #fff;
  display: flex;
  justify-content: center;
}
.filmnetLogo {
  display: flex;
  justify-content: center;
  transform: translateY(50px);
  width: 100%;
  height: 100px;
  background-color: #000;
  padding: 15px;
}
@media only screen and (max-width: 1024px) {
  .header {
    padding-top: 30px;
  }
}

@media only screen and (max-width: 1024px) {
  .header {
    padding-top: 20px;
  }

  .headerMenu {
    flex-direction: column;
  }

  .headerMenu li a {
    color: #000;
  }
  .logoHeader img {
    width: 100%;
  }
  .logoHeader {
    transform: translateY(10px);
    position: relative;
  }
  .filmnetLogo {
    transform: translateY(20px);
    height: 60px;
    padding: 0;
    padding-bottom: 10px;
  }
}
