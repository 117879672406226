.footer__inner-container__description-text {
  margin-top: 48px;
  text-align: center;
  font-size: 16px;
}

.footer__inner-container__menu {
  display: flex;
  width: 100%;
  border-bottom: 1px solid rgba(211, 215, 225, 1);
  margin-top: 48px;
  padding-bottom: 24px;
  margin-bottom: 24px;
}

.footer__inner-container__menu li {
  margin-left: 32px;
  font-size: 16px;
  color: rgba(123, 128, 133, 1);
}

.footer__bg-mobile {
  display: none;
}

.footer__inner-container__copyright {
  margin-bottom: 30px;
}

.footer__inner-container__socialNetwork {
  margin-bottom: 20px;
}

.footer__inner-container__socialNetwork ul {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #d8d8d8;
  padding-top: 20px;
}

.footer__inner-container__socialNetwork ul li a {
  width: 35px;
  height: 35px;
  display: flex;
  padding: 5px;
}

.footer__inner-container__socialNetwork ul li a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 992px) {
  .footer {
    margin-top: 200px;
  }
}

/* responsive */
@media (max-width: 768px) {
  .footer {
    overflow: visible;
  }

  .footer__dot-bg {
    width: 119px;
  }

  .footer__rotate-bg {
    display: none;
  }

  .footer__bg-mobile {
    display: block;
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .footer__inner-container {
    margin-top: 56px;
  }

  .footer__inner-container__description-text {
    font-size: 13px;
    margin-top: 28px;
  }

  .footer__dot-bg {
    top: -26px;
  }

  .footer__inner-container__menu li {
    margin: 0 6px;
    font-size: 13px;
  }

  .footer__inner-container__copyright {
    font-size: 13px;
    text-align: center;
  }
}

@media only screen and (max-width: 480px) {
  .footer {
    margin-top: 0
  }
}