@font-face {
  font-family: 'IRANYekanFaNum';
  src: url('./assets/fonts/IRANYekanFaNum.svg#IRANYekanFaNum') format('svg'), url('./assets/fonts/IRANYekanFaNum.ttf') format('truetype'), url('./assets/fonts/IRANYekanFaNum.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'IRANYekan';
  src: url('./assets/fonts/IRANYekanRegular(FaNum).eot');
  src: url('./assets/fonts/IRANYekanRegular(FaNum).eot?#iefix') format('embedded-opentype'), url('./assets/fonts/IRANYekanRegular(FaNum).woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

body,
html {
  margin: 0;
  padding: 0;
  font-family: "IRANYekan", "IRANYekanFaNum";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  direction: rtl;
  overflow-x: hidden;
  background: #5818b9;
}

main {
  position: relative;
  background: #5818b9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul,
ul li {
  margin: 0;
  list-style-type: none;
  padding: 0;
}

a {
  text-decoration: none;
}

figure,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}